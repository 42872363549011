import React, { useEffect, useState } from "react";
import MapComponent from "../../../components/googleMapComponents/mapComponent";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notifyFailure, notifySuccess } from "../../../components/toast";
import CustomButton from "../../../components/CustomButton";
import {
  asyncGetCurrentRide,
  cancelRide,
  updateDriverLocation,
} from "../../../redux/slices/rideSlice";
import socket from "../../../services/socket";
import RatingModal from "../../../components/modals/ratingModal";
import { async } from "q";
import axios from "axios";
import axiosPrivate from "../../../services/api";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ChatPopup from "../../../components/modals/chatPopup";

function RideView() {
  const [showRatingPopup, setShowRatingPopup] = useState(false);
  const [ChatOpen, setChatOpen] = useState(false);
  const [ratingDone, setRatingDone] = useState(false);
  const [rating, setRating] = useState(3);
  const [feedback, setFeedback] = useState("");
  const [isloading, setIsloading] = useState(false);
  // const [cancelRating, setCancelRating] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;

  const rides = useSelector((state) => state.rides);
  const { currentRide, driverLocation, rideStatus } = rides;

  const cancelRideHandler = (res) => {
    socket.emit(
      "cancel-ride-v2",
      {
        rideId: currentRide._id || currentRide._id,
        passenger: currentRide.passenger._id || currentRide.passenger._id,
        driver: currentRide.driver._id || currentRide.driver._id,
        cancelDetails: {
          cancelledBy: auth.userInfo._id || auth.userInfo._id,
          modelNameOfTheUserType: "Passenger",
          cancelReason: "Other",
          timestamp: new Date(),
        },
      },
      (res) => {
        console.log("ride cancelled==>", res);
        dispatch(cancelRide(currentRide._id || currentRide._id));
        notifySuccess("Ride cancelled successfully!");
      }
    );
  };

  // useEffect(() => {
  //   dispatch(asyncGetCurrentRide());
  // }, []);
  useEffect(() => {
    dispatch(asyncGetCurrentRide());

    socket.on("driver-location", (data) => {
      console.log("driver location==>", data);
      dispatch(updateDriverLocation(data));
    });

    return () => {
      socket.off("driver-location");
    };
  }, []);

  useEffect(() => {
    if (currentRide?.status === "completed") {
      // if (currentRide) {
      // navigate(`/my-rides/${currentRide._id}`);
      console.log("working");
      setShowRatingPopup(true);
    }
  }, [currentRide]);

  const handleSubmit = async () => {
    const ratingPostData = {
      ratingTo: currentRide.driver._id,
      modelNameOfTheRatingToUser: "Driver",
      ratingBy: currentRide.passenger?._id,
      modelNameOfTheRatingByUser: "Passenger",
      ride: currentRide._id,
      rating: rating,
      feedback: feedback,
    };
    try {
      setIsloading(true);
      const respones = await axiosPrivate.post(`/ratings`, ratingPostData);
      setIsloading(false);
      setShowRatingPopup(false);
      setRatingDone(true);
      notifySuccess("Rating done successfully!");
      console.log("Response data:", respones);
      console.log(isloading);
    } catch (error) {
      notifyFailure(error.response.data.message);
      setIsloading(false);
      // Handle any errors that occur during the request
      console.error("Error:", error);
    }

    // Call your API to submit the rating
    // handleRatingSubmit({
    //   ratingTo: ratingTo, // Replace with the appropriate user ID
    //   rating: rating,
    //   feedback: feedback,
    // });
    // handleClose(); // Close the modal
  };

  const viewRideDetailsHandler = () => {
    navigate(`/rides/${currentRide._id}`);
  };

  //   if (!currentRide) {
  //     // notifyFailure("You don't have an active ride!");

  //     return <Navigate to="/book-a-ride" />;
  //   }
  return (
    <>
      <div className=" mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <p>
            {currentRide.rideActions[currentRide.rideActions.length - 1] &&
              currentRide.rideActions[currentRide.rideActions.length - 1]
                .messageForPassenger}
          </p>
          <CustomButton
            // type="submit"
            // disabled={isLoading}
            // isLoading={isLoading}
            // onClick={handlePickupDirections}
            title={"Open Map"}
            className={"btn btn-primary"}
          />
        </div>
        {/* <div>
          <p>
            {
              currentRide.rideActions[currentRide.rideActions.length - 1]
                .messageForDriver
            }
          </p>
        </div> */}
      </div>
      {/* {hasActiveRide ? ( */}
      <div className="row">
        <div className="col-12 col-md-6 ">
          {/* <div className="mb-4">
          <h6>Ride Actions:</h6>
          <div className="d-flex justify-content-start flex-wrap">
            {rideActionsArr.map((action, index) => {
              const actionDone = currentRide.rideActions[index];
              // console.log("ride actio found==", actionDone);
              return (
                <CustomButton
                  className={`btn mr-2 mb-2 ${
                    actionDone ? "btn-success" : "btn-warning"
                  }`}
                  title={action.name}
                  icon={
                    actionDone ? (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                    )
                  }
                />
              );
            })}
          </div>
        </div> */}
          {/* Passenger Information */}
          <div className="mb-3">
            <h6>Driver Information:</h6>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    currentRide.driver.profileImageUrl.length > 0
                      ? currentRide.driver.profileImageUrl
                      : "/assets/images/passenger.png"
                  }
                  height={100}
                  alt="Passenger"
                  className="mr-3"
                />
                <div className="mx-3">
                  <p className="mb-0">
                    <span className="fw-semibold">Name:</span>{" "}
                    {currentRide.driver?.firstName}{" "}
                    {currentRide.driver?.lastName}
                  </p>
                  {/* <p className="mb-0">Email: john@example.com</p> */}
                  <p className="mb-0">
                    <span className="fw-semibold">Phone:</span>{" "}
                    {currentRide?.driver?.phone}
                  </p>
                </div>
              </div>
              <Button variant="warning" onClick={() => setChatOpen(!ChatOpen)}>
                Chat
              </Button>{" "}
              {ChatOpen && (
                <ChatPopup show={ChatOpen} setChatOpen={setChatOpen} />
              )}
            </div>
          </div>
          {/* Vehicle Information */}
          <div className="mb-3">
            <h6 className="">Vehicle Information:</h6>
            <div className="">
              <p className="mb-0">
                <span className="fw-semibold">Name:</span>{" "}
                {currentRide.vehicle?.make} {currentRide?.vehicle?.model}{" "}
                {currentRide?.vehicle?.year}{" "}
                {currentRide?.vehicle?.color
                  ? `( ${currentRide?.vehicle?.color} )`
                  : ""}
              </p>
              {/* <p className="mb-0">Email: john@example.com</p> */}

              <p className="mb-0">
                <span className="fw-semibold">Plate Number:</span>{" "}
                {currentRide?.vehicle?.plateNumber}
              </p>
            </div>
          </div>
          {/* Pickup and Dropoff Locations */}
          <div className="mb-3">
            <h6>Pickup Location:</h6>
            <div className="d-flex align-items-center">
              {/* <img
                src={"/assets/images/location-icon.png"}
                width={20}
                alt="Location"
                className="mr-2 mt-1"
              /> */}
              <span className="mr-2">
                <FaMapMarkerAlt size={16} />
              </span>

              <p className="mb-0 mt-1">
                {currentRide?.rideRequest?.pickup?.address?.formattedAddress}
              </p>
            </div>
          </div>
          <div className="mb-3">
            <h6>Dropoff Location:</h6>
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <FaMapMarkerAlt size={16} />
              </span>
              <p className="mb-0 mt-1">
                {currentRide?.rideRequest?.dropoff?.address?.formattedAddress}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-start">
            {/* Fare and Distance */}
            <div className="mr-5">
              <h6>Estimated Distance / Duration:</h6>
              <p>
                {currentRide?.rideRequest?.distance} /{" "}
                {currentRide?.rideRequest?.duration}
              </p>
            </div>
            <div className="mx-3 mb-3">
              <h6>Estimated Fare:</h6>
              <p>
                {currentRide?.rideRequest?.fareDetails?.totalFare}{" "}
                {currentRide?.rideRequest?.fareDetails?.currency}
              </p>{" "}
            </div>
          </div>
          <div className="d-flex gap-2">
            {!currentRide.route.arrivedAtPickup && (
              <CustomButton
                // type="submit"
                // disabled={isLoading}
                // isLoading={isLoading}
                onClick={cancelRideHandler}
                title={"Cancel Ride"}
                className={"btn btn-danger "}
              />
            )}

            {currentRide.status === "completed" && (
              <CustomButton
                // type="submit"
                // disabled={isLoading}
                // isLoading={isLoading}
                onClick={viewRideDetailsHandler}
                title={"View Details"}
                className={"btn btn-primary"}
              />
            )}

            {!ratingDone && currentRide.route.rideEnded && (
              <CustomButton
                // type="submit"
                // disabled={isLoading}
                // isLoading={isLoading}
                onClick={() => setShowRatingPopup(true)}
                title={"Rate Now"}
                className={"btn btn-primary"}
              />
            )}
          </div>

          {/* <div className="">
            <h6>Ride Actions:</h6>
            <div className="d-flex justify-content-start">
              <CustomButton
                className="btn btn-warning mr-3"
                title="Pick Customer"
              />
              <CustomButton
                className="btn btn-warning mr-3"
                title="Start Ride"
                icon={
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  ></i>
                }
              />
              <CustomButton
                className="btn btn-warning mr-3"
                title="End Ride"
                icon={<i className="fa fa-check" aria-hidden="true"></i>}
              />
            </div>
          </div> */}
        </div>
        <div className="col-12 col-md-6">
          <MapComponent driverLocation={driverLocation} />
        </div>
      </div>

      <RatingModal
        show={showRatingPopup}
        handleSubmit={handleSubmit}
        rating={rating}
        handleClose={() => setShowRatingPopup(false)}
        setRating={setRating}
        feedback={feedback}
        setFeedback={setFeedback}
        isLoading={isloading}
      />
    </>
  );
}

export default RideView;
